import EventBus from '@/eventBus';
import Events from '@/events';
import hadrianLoader from '@/shared/viewParts/hadrianLoader/hadrianLoader.vue';

let dragModal: Element;
let lastModalX = 0;
let lastModalY = 0;
let modalShowX = -1;
let modalShowY = -1;

const moveModal = (eMove: MouseEvent) => {
  const e = eMove || window.event;
  e.preventDefault();
  const modalX = lastModalX - e.clientX;
  const modalY = lastModalY - e.clientY;
  lastModalX = e.clientX;
  lastModalY = e.clientY;
  (dragModal as any).style.left = ((dragModal as any).style.left.replace('px', '') as number) - modalX + 'px';
  (dragModal as any).style.top = ((dragModal as any).style.top.replace('px', '') as number) - modalY + 'px';
};

const mouseUpModal = (eup: MouseEvent) => {
  document.removeEventListener('mousemove', moveModal);
  document.removeEventListener('mouseup', mouseUpModal);
  (dragModal as any).children[0].children[0].style.cursor = 'grab';
  window.focus();

  if ((eup.target as any).getAttribute('lang')) {
    document.addEventListener('mouseup', mouseUpOutsideWindowFixModal);
  }
};

const mouseUpOutsideWindowFixModal = (eup: MouseEvent) => {
  const doubleClick = document.getElementById((eup.target as any).id);
  const targetClass = (eup.target as any).getAttribute('class');

  if (!!doubleClick && !!targetClass && targetClass.startsWith('modal fade')) {
    doubleClick.click();
    document.removeEventListener('mouseup', mouseUpOutsideWindowFixModal);
  }
};

export enum ModalVariant {
  ContextMenu = 'context-menu',
  Thin = 'thin',
  Thin2 = 'thin-2',
  Medium = 'medium',
  Wide = 'wide',
  Wide2 = 'wide-2',
  Wide3 = 'wide-3',
  Wide4 = 'wide-4',
  Fullscreen = 'fullscreen',
  Minimum = 'minimum',
  Default = 'default',
}

export enum ModalInitialPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'hadrianModal',
  props: {
    title: {
      type: String,
    },
    leftButtonText: {
      type: String,
    },
    rightButtonText: {
      type: String,
    },
    variant: {
      type: String as () => ModalVariant,
      default: ModalVariant.Default,
    },
    rightButtonDisabled: {
      type: Boolean,
      default: false,
    },
    hideOnRightClick: {
      type: Boolean,
      default: true,
    },
    hideOnLeftClick: {
      type: Boolean,
      default: true,
    },
    hideRightButton: {
      type: Boolean,
      default: false,
    },
    hideLeftButton: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
    },
    hideHeaderClose: {
      type: Boolean,
      default: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    rightButtonClass: {
      type: String,
    },
    leftButtonClass: {
      type: String,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    isPartsCatalogModal: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    initialPosition: {
      type: String as () => ModalInitialPosition,
      default: ModalInitialPosition.Center,
    },
    openOnCursor: {
      type: Boolean,
      default: false,
    },
    openOnCursorX: {
      type: Number,
    },
    openOnCursorY: {
      type: Number,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    noOpacityBackdrop: {
      type: Boolean,
      default: false,
    },
    forceRenderDom: {
      type: Boolean,
      default: false,
    },
    avoidSubmitTrap: {
      type: Boolean,
      default: false,
    },
    showCenterButton: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    centerButtonText: {
      type: String,
    },
  },
  components: {
    hadrianLoader,
  },
  data() {
    return {
      isDirty: false,
      showModal: false,
      mousedownEvent: null as MouseEvent | null,
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.preventBodyScroll();
      EventBus.$emit(Events.formatMeasureInput);
      // Reset Modal position on any showing after the first one
      if ((modalShowX !== -1 || modalShowY !== -1) && !this.openOnCursor) {
        (dragModal as any).style.left = modalShowX;
        (dragModal as any).style.top = modalShowY;
      }

      // NextTick delay is to let the dom load the modal on first show
      this.$nextTick(() => {
        dragModal = document.querySelector('.hadrian-modal__dialog') as Element;
        // Save initial position of the modal
        if (modalShowX === -1 && modalShowY === -1) {
          modalShowX = (dragModal as any).style.left;
          modalShowY = (dragModal as any).style.top;
        }
        if (this.openOnCursor) {
          (dragModal as any).style.left = this.openOnCursorX + 'px';
          (dragModal as any).style.top = this.openOnCursorY + 'px';
        } else if (this.draggable) {
          this.setHeaderDrag();
        }

        if (this.avoidSubmitTrap) {
          (this.$refs['submit-trap'] as HTMLElement).remove();
        }

        (this.$refs['submit-trap'] as HTMLElement)?.focus({ preventScroll: true });
      });
    },
    hide() {
      document.removeEventListener('mousemove', moveModal);
      document.removeEventListener('mouseup', mouseUpModal);
      document.removeEventListener('mouseup', mouseUpOutsideWindowFixModal);
      this.showModal = false;
      this.preventBodyScroll(false);
      this.$emit('hide');
    },
    preventBodyScroll(preventScroll = true) {
      const body = document.querySelector('body') as HTMLBodyElement;
      if (body) {
        if (preventScroll) {
          if (body.classList.contains('hadrian-modal-open')) {
            return;
          }
          const bodyClasses = ['hadrian-modal-open'];
          if (body.scrollHeight > body.clientHeight) {
            bodyClasses.push(`padding-right-${window.innerWidth - document.documentElement.clientWidth}`);
          }
          body.classList.add(...bodyClasses);
        } else {
          setTimeout(() => {
            body.className = '';
          }, 400);
        }
      }
    },
    resetToInitialPosition() {
      (dragModal as any).style.left = modalShowX;
      (dragModal as any).style.top = modalShowY;
    },
    leftButtonClick() {
      this.$emit('left-button-click');
      if (this.hideOnLeftClick) {
        this.isDirty = false;
        this.hide();
      }
    },
    centerButtonClick() {
      this.$emit('center-button-click');
    },
    onSubmit(event: KeyboardEvent) {
      if (this.rightButtonDisabled || !this.isForm) {
        return;
      }

      if (event && event.shiftKey) {
        return;
      }

      if (event?.key === 'Enter' && this.isPartsCatalogModal) {
        return;
      }

      this.$emit('right-button-click');
      if (this.hideOnRightClick) {
        this.isDirty = false;
        this.hide();
      }
    },
    handleShow() {
      this.isDirty = true;
    },
    handleCancel() {
      this.$emit('hide');
      if (!this.isDirty || this.noCloseOnBackdrop) {
        return;
      }
      this.showModal = false;
      this.$emit('left-button-click');
    },
    onClickOut(event: MouseEvent) {
      const clickStartedInside = this.contains(this.$refs.content, this.mousedownEvent?.target);
      const clickFinishedInside = this.contains(this.$refs.content, event.target);
      if (
        !this.showModal ||
        this.noCloseOnBackdrop ||
        !this.contains(document, event.target) ||
        clickStartedInside ||
        clickFinishedInside
      ) {
        return;
      }
      this.hide();
    },
    contains(parent: any, child: any) {
      if (!parent || typeof parent.contains !== 'function' || !child) {
        return false;
      }

      return parent.contains(child);
    },
    handleShown() {
      this.$emit('handleShown');
    },
    moveToCursor(pointerPosition: any) {
      (dragModal as any).style.left =
        ((dragModal as any).style.left.replace('px', '') as number) - pointerPosition.x + 'px';
      (dragModal as any).style.top =
        ((dragModal as any).style.top.replace('px', '') as number) - pointerPosition.y + 'px';
    },
    setHeaderDrag() {
      dragModal = document.querySelector('.hadrian-modal__dialog') as Element;
      (dragModal as any).children[0].children[0].style.cursor = 'grab';
      const dragModalHeader = dragModal.querySelector('header') as Element;
      if (!this.hideHeader) {
        dragModalHeader.addEventListener('mousedown', (e: any) => {
          if (e.button === 0) {
            lastModalX = e.clientX;
            lastModalY = e.clientY;
            e = e || window.event;
            e.preventDefault();
            (dragModal as any).children[0].children[0].style.cursor = 'grabbing';
            document.addEventListener('mousemove', moveModal);
            document.addEventListener('mouseup', mouseUpModal);
          }
        });
      }
    },
  },
  computed: {
    isForm(): boolean {
      return !this.hideRightButton && !this.hideFooter;
    },
    modal(): any {
      return this.$refs.modal;
    },
    computedLeftButtonText(): string {
      return this.leftButtonText ? this.leftButtonText : (this.$t('projects.details.settings.cancel') as string);
    },
    computedRightButtonText(): string {
      return this.rightButtonText ? this.rightButtonText : (this.$t('projects.details.settings.save') as string);
    },
    hasHeaderSlot() {
      return !!this.$slots['modal-header'];
    },
    hasFooterSlot() {
      return !!this.$slots['modal-footer'];
    },
    isContextMenu(): boolean {
      return this.variant === ModalVariant.ContextMenu;
    },
    sizeClass(): Record<string, boolean> {
      // no variant = 832px
      return {
        'hadrian-modal--context-menu': this.variant === ModalVariant.ContextMenu, // 160px
        'hadrian-modal--thin': this.variant === ModalVariant.Thin, // 404px
        'hadrian-modal--thin-2': this.variant === ModalVariant.Thin2, // 484px
        'hadrian-modal--medium': this.variant === ModalVariant.Medium, // 600px
        'hadrian-modal--wide': this.variant === ModalVariant.Wide, // 880px
        'hadrian-modal--wide-2': this.variant === ModalVariant.Wide2, // 1150px
        'hadrian-modal--wide-3': this.variant === ModalVariant.Wide3, // 1150px
        'hadrian-modal--wide-4': this.variant === ModalVariant.Wide4, // 1400px
        'hadrian-modal--fullscreen': this.variant === ModalVariant.Fullscreen,
        'hadrian-modal--minimum': this.variant === ModalVariant.Minimum,
      };
    },
    positionClass(): Record<string, boolean> {
      return {
        'hadrian-modal--left':
          this.variant !== ModalVariant.ContextMenu && this.initialPosition === ModalInitialPosition.Left,
        'hadrian-modal--right':
          this.variant !== ModalVariant.ContextMenu && this.initialPosition === ModalInitialPosition.Right,
        'hadrian-modal--center':
          this.variant !== ModalVariant.ContextMenu && this.initialPosition === ModalInitialPosition.Center,
        'hadrian-modal--on_cursor': this.openOnCursor && this.variant === ModalVariant.ContextMenu,
      };
    },
    classes(): Record<string, boolean> {
      return Object.assign(this.sizeClass, this.positionClass);
    },
  },
  watch: {
    '$route.path'() {
      this.hide();
    },
    isContextMenu() {
      this.$nextTick(() => {
        this.setHeaderDrag();
      });
    },
  },
});
