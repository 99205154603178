export enum PillThemes {
  Default = 'default',
  Alert = 'alert',
  Purple = 'purple',
  Blue = 'blue',
  Green = 'green',
  Grey = 'grey',
  LightGrey = 'lightgrey',
  Turquoise = 'turquoise',
  Red = 'red',
  Yellow = 'yellow',

  // lead status pills start here
  OpaqueGreen = 'opaque-green',
  OpaqueBlue = 'opaque-blue',
  OpaqueOrange = 'opaque-orange',
  OpaqueGrey = 'opaque-grey',

  PastelWhite = 'pastel-white',
  PastelBlue = 'pastel-blue',
  PastelYellow = 'pastel-yellow',
  PastelPurple = 'pastel-purple',
  PastelGreen = 'pastel-green',
  PastelRed = 'pastel-red',
  Inverted = 'inverted',
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'pill',
  props: {
    theme: {
      type: String as () => PillThemes,
      default: PillThemes.Default,
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.setTheme();
  },
  methods: {
    setTheme() {
      (this.$refs.pill as HTMLElement)?.style.setProperty('--background', `var(--${this.theme}-background)`);
      (this.$refs.pill as HTMLElement)?.style.setProperty('--color', `var(--${this.theme}-color)`);
      (this.$refs.pill as HTMLElement)?.style.setProperty('--border', `var(--${this.theme}-border)`);
    },
  },
  watch: {
    theme() {
      this.setTheme();
    },
  },
});
