import { defineComponent } from 'vue';
import { TranslateResult } from 'vue-i18n';
import iconChevron from '@/shared/icons/chevron/chevron.vue';
import hadrianTooltip from '@/shared/viewParts/hadrianTooltip/hadrianTooltip.vue';
import GridHelper from '@/helpers/grid';

export const H_TABLE_ACTION_COLUMN_WIDTH_REM = 4;
export const H_TABLE_EXPAND_COLUMN_WIDTH_REM = 4;

export enum RowThemes {
  Disabled = 'disabled',
}

export interface HTableColumn {
  key: string;
  align?: 'left' | 'center' | 'right';
  sortable?: boolean;
  width?: number;
  class?: string;
  headerClass?: string;
}

export interface HTableSort {
  key: string;
  ascending: boolean;
}

export default defineComponent({
  name: 'h-table',
  props: {
    columns: {
      type: Array as () => HTableColumn[],
      required: true,
    },
    rows: {
      type: Array as () => any[],
      required: true,
    },
    keyAttribute: {
      type: String,
      default: 'id',
    },
    rowTheme: {
      type: Function as never as () => () => RowThemes | null,
      default: () => null,
    },
    rowClass: {
      type: Function as never as () => () => string | null,
      default: () => null,
    },
    defaultSort: {
      type: Object as () => HTableSort,
    },
    isExpandedByDefault: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
    },
    actionable: {
      type: Boolean,
    },
    hasFooter: {
      type: Boolean,
    },
    emptyText: {
      type: String,
    },
    showHeaderExpand: {
      type: Boolean,
      default: true, // Default value for showHeaderExpand
    },
    selectable: {
      type: Boolean,
      default: false, // Default value for selectable rows
    },
  },
  components: {
    iconChevron,
    hadrianTooltip,
  },
  data() {
    return {
      sort: this.defaultSort || ({} as HTableSort),
      expansion: {} as Record<string | number, boolean>,
      isAllExpanded: this.isExpandedByDefault,
      selectedRows: new Set<any>(), // Track selected rows
    };
  },
  mounted() {
    if (this.isAllExpanded) {
      this.expandAllRows();
    }
  },
  methods: {
    toggleRowSelection(row: any) {
      if (this.selectedRows.has(row)) {
        this.selectedRows.delete(row); // Deselect if already selected
      } else {
        this.selectedRows.add(row); // Select the row
      }
      this.$emit('onRowSelect', row); // Emit selected row
    },
    isRowSelected(row: any): boolean {
      return this.selectedRows.has(row);
    },
    getRowUniqueId(row: any, index: number) {
      const id = index.toString();
      return row[this.keyAttribute] ? row[this.keyAttribute] + id : id;
    },
    getHeaderCellContainerElement(column: HTableColumn): 'button' | 'div' {
      return !!this.rows.length && !!column.sortable ? 'button' : 'div';
    },
    onHeaderCellClick(column: HTableColumn) {
      if (!column.sortable) {
        return;
      }

      this.onSort(column);
    },
    onSort(column: HTableColumn) {
      const ascending = this.sort.key !== column.key || !this.sort.ascending;
      this.sort = { key: column.key, ascending };
      this.$emit('sort', this.sort);
    },
    getSortIndicator(column: HTableColumn): string {
      if (this.sort.key !== column.key) {
        return '<i class="fas fa-sort" />';
      }

      if (this.sort.ascending) {
        return '<i class="fas fa-caret-up" />';
      }

      return '<i class="fas fa-caret-down" />';
    },
    onToggleExpand(row: any, index: number) {
      const key = this.getRowUniqueId(row, index);
      this.expansion = { ...this.expansion, [key]: !this.expansion[key] };

      this.$emit('expand-row', row);
    },
    onToggleExpandAll() {
      this.isAllExpanded = !this.isAllExpanded;
      this.expandAllRows();
      this.$emit('expand-all', this.isAllExpanded);
    },
    expandAllRows() {
      this.expansion = this.rows.reduce(
        (expansion, row, i) => ({ ...expansion, [this.getRowUniqueId(row, i)]: this.isAllExpanded }),
        this.expansion
      );
    },
    getColumnAlignment(column: HTableColumn): 'flex-start' | 'center' | 'flex-end' {
      switch (column.align) {
        case 'center':
          return 'center';
        case 'right':
          return 'flex-end';
        default:
          return 'flex-start';
      }
    },
  },
  computed: {
    rowStyle(): Record<string, string> {
      const addOns: number[] = [];
      if (this.actionable) {
        addOns.push(H_TABLE_ACTION_COLUMN_WIDTH_REM);
      }

      if (this.expandable) {
        addOns.push(H_TABLE_EXPAND_COLUMN_WIDTH_REM);
      }

      return { 'grid-template-columns': GridHelper.computePercentageTemplateColumns(this.columns, addOns) };
    },
    expandAllButtonLabel(): TranslateResult {
      if (this.isAllExpanded) {
        return this.$t('table.collapseAll');
      }

      return this.$t('table.expandAll');
    },
    headerExpandVisible(): boolean {
      // Renamed to avoid conflict
      return this.showHeaderExpand; // Ensure you use the prop correctly
    },
  },
  watch: {
    rows: {
      handler() {
        this.expandAllRows();
      },
      deep: true,
    },
  },
});
