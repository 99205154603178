import { IconColors } from '@/shared/viewParts/filteredSelector/filteredSelector';
import { Component } from 'vue';

export enum FilterType {
  ProductType,
  SalesAgency,
  Distributor,
  Organization,
  DateRange,
  PriceRange,
  OrderStatus,
  PendingShippingQuoteSelected,
  SalesArea,
  LeadStatus,
  ProjectType,
  LeadAssignmentStatus,
  AssignmentType,
}

export class FilterBoxContent {
  public label: string | undefined;
  public id: string;
  public filterType: FilterType;
  public icon?: Component;
  public iconColor?: IconColors;
}

export class FilterBoxes {
  public distributor: FilterBoxContent;
  public salesAgency: FilterBoxContent;
  public productType: FilterBoxContent;
  public organization: FilterBoxContent;
  public dateRange: FilterBoxContent;
  public priceRange: FilterBoxContent;
  public orderStatus: FilterBoxContent;
  public salesArea: FilterBoxContent;
  public leadStatus: FilterBoxContent;
  public leadAssignmentStatus: FilterBoxContent;
  public projectType: FilterBoxContent;
}
