import ProjectPartsService from '@/endpoints/projectPartsService';
import ProjectService from '@/endpoints/projectService';
import ProjectSettingsService from '@/endpoints/projectSettingsService';
import RoomPartsService from '@/endpoints/roomPartsService';
import { HttpStatusCode } from '@/enums/httpCodes';
import EventBus from '@/eventBus';
import Events from '@/events';
import ArrayHelper from '@/helpers/arrayHelper';
import { saveProjectLinks } from '@/endpoints/leadsService';
import HttpResponse from '@/interfaces/HttpResponse';
import IDateRange from '@/interfaces/IDateRange';
import IPriceRange from '@/interfaces/IPriceRange';
import { ProjectResponse } from '@/interfaces/responses/projectResponse';
import ShippingAddress from '@/models/addresses/shippingAddress';
import ShippingAddressCreateRequest from '@/models/addresses/shippingAddressCreateRequest';
import MarkupResponse from '@/models/project/markupResponse';
import Project from '@/models/project/project';
import {
  ChangePartsFinish,
  DuplicateNoteToRoomsRequest,
  ProjectCreationRequest,
  ProjectDuplicateOperation,
  ProjectPatchOperation,
  ProjectRoomParts,
  ProjectsGetParams,
  ProjectsResponse,
  RoombBOMParts,
  RoomBomReply,
  RoomBomRequest,
  SaveMarkupRequest,
  Unit,
} from '@/models/project/projectApiInterfaces';
import ProjectSettings from '@/models/project/projectSettings';
import SpecialCharges from '@/models/project/specialCharges';
import {
  RoomCustomLengthPartRemovalRequest,
  RoomPartsDeleteBomEditRequest,
  RoomPartsDeleteRequest,
  RoomPartsEditRequest,
  RoomPartsResponse,
  RoomSuggestedPartsEditRequest,
} from '@/models/room/roomApiInterfaces';
import RoomParts from '@/models/room/roomParts';
import User from '@/models/user/user';
import { redirectUserToHomePageWithNotification } from '@/router';
import { AxiosError } from 'axios';
import { action, Module, mutation, VuexModule } from 'vuex-class-component';
import { vxManager } from '../store';
import { ProjectProductTypes } from '@/models/project/projectApiInterfaces';

interface RoomBomPartsUpdate {
  readonly roomId: string;
  readonly roomParts: RoomParts[];
}

interface RoomBomPartsDelete {
  readonly roomId: string;
  readonly partId: string;
}

interface SpecialChargesHistoryUpdateRequest {
  readonly projectId: string;
  readonly specialCharges: SpecialCharges[];
}

interface ShippingAddressPayload {
  readonly projectId: string;
  readonly shippingAddress: ShippingAddressCreateRequest;
}

@Module()
export class ProjectsModule extends VuexModule {
  private projectStartDate = '';
  private projectEndDate = '';
  private projectMinPrice = 0;
  private projectMaxPrice = 0;
  private projects: Project[] = [];
  private projectParams: ProjectsGetParams = {};
  private currentProjectId = '';
  private areProjectsLoaded = false;
  private total = 0;
  private specialChargeHistoryByProject: { [projectId: string]: SpecialCharges[] } = {};
  private leadAssignmentId: number | null = null;

  @action({ mode: 'mutate' })
  public async clearState(): Promise<void> {
    this.clearProjectsData();
  }

  @action({ mode: 'mutate' })
  public async setCurrentProject(projectId: string) {
    let project = this.projectById(projectId);
    if (project) {
      this.changeCurrentProject(projectId);
    } else {
      // TODO: Is this necessary?
      // Yes, when opening the project page in a new tab
      try {
        const response = await ProjectService.getProjectStripped(projectId);
        this.addProjects([response]);
        project = this.projectById(projectId);
        if (project) {
          this.changeCurrentProject(projectId);
        }
      } catch (err: any) {
        handleGetProjectError(err);
      }
    }
  }

  @action({ mode: 'mutate' })
  public async setCurrentProjectNoAuth(projectId: string) {
    let project = this.projectById(projectId);
    if (project) {
      this.changeCurrentProject(projectId);
    } else {
      // TODO: Is this necessary?
      // Yes, when opening the project page in a new tab
      try {
        const response = await ProjectService.getProjectStrippedNoAuth(projectId);
        this.addProjects([response]);
        project = this.projectById(projectId);
        if (project) {
          this.changeCurrentProject(projectId);
        }
      } catch (err: any) {
        handleGetProjectError(err);
      }
    }
  }

  @action({ mode: 'mutate' })
  public addProjectRangeDates(dates: IDateRange) {
    this.changeProjectStartDateFilter(dates.startDate);
    this.changeProjectEndDateFilter(dates.endDate);
  }

  @action({ mode: 'mutate' })
  public addProjectRangePrice(prices: IPriceRange) {
    this.changeProjectMinPriceFilter(prices.minPrice);
    this.changeProjectMaxPriceFilter(prices.maxPrice);
  }

  @action({ mode: 'mutate' })
  public async fetchProjects(params: ProjectsGetParams): Promise<HttpResponse<ProjectsResponse>> {
    this.projectsNeedToLoad();
    this.setProjectGetParams(params);
    const response = await ProjectService.getProjects(this.projectParams);
    this.addProjects(response.data.entities);
    this.changeTotal(response.data.total);
    this.finishProjectLoading();
    return response;
  }

  @action({ mode: 'mutate' })
  public async fetchProjectBom(projectId: string): Promise<void> {
    const res = await ProjectPartsService.getBomParts(projectId);
    this.setProjectRoomParts(res.data.entity.partsInBOMList);
    this.setProjectUnits(res.data.entity.units);
    this.setPartsTotal(res.data.entity);
    this.setRoomBomParts(res.data.entity.roomsBomReply);
    this.setRoomsTotals(res.data.entity.roomsBomReply);
  }

  @action({ mode: 'mutate' })
  public async fetchRoomBom(request: RoomBomRequest): Promise<void> {
    EventBus.$emit(Events.getRoomBom);
    const res = await ProjectPartsService.getRoomBomParts(request);
    this.setRoomBomPartsSingleRoom(res.data);
    EventBus.$emit(Events.getRoomBomDone);
  }

  @action({ mode: 'mutate' })
  public async fetchRoomBomMissingParts(request: RoomBomRequest): Promise<void> {
    const res = await ProjectPartsService.updateRoomBomMissingParts(request);
    this.setRoomBomPartsSingleRoom(res.data);
  }

  @action({ mode: 'mutate' })
  public async fetchProject(projectId: string): Promise<void> {
    try {
      const response = await ProjectService.getProject(projectId);
      this.updateProject(response);
    } catch (err: any) {
      handleGetProjectError(err);
    }
  }

  @action({ mode: 'mutate' })
  public async fetchFullProject(projectId: string): Promise<void> {
    try {
      await this.fetchProjectBom(projectId);
      const response = await ProjectService.getProject(projectId);
      this.updateProject(response);
    } catch (err: any) {
      handleGetProjectError(err);
    }
  }

  @action({ mode: 'mutate' })
  public async refreshProject(projectId: string): Promise<void> {
    try {
      await vxManager.roomDesignerModule.withRoomLoading(async () => {
        await this.fetchProjectBom(projectId);
        const response = await ProjectService.getProject(projectId);
        this.updateProject(response);
      });
    } catch (err: any) {
      handleGetProjectError(err);
    }
  }

  @action({ mode: 'mutate' })
  public async fetchProjectHasSpecialColors(projectId: string): Promise<boolean> {
    return await ProjectService.getProjectHasSpecialColor(projectId);
  }

  @action({ mode: 'mutate' })
  public async fetchIsProjectQuotable(projectId: string): Promise<boolean> {
    return await ProjectService.isProjectQuotable(projectId);
  }

  @action({ mode: 'mutate' })
  public async fetchProjectCreator(projectId: string): Promise<void> {
    const response = await ProjectService.getProjectCreator(projectId);
    this.addProjectCreator(response);
  }

  @action({ mode: 'mutate' })
  public async createProject(projectRequest: ProjectCreationRequest): Promise<HttpResponse<ProjectResponse>> {
    const leadAssignmentId = vxManager.projectsModule.getLeadAssignmentId;
    const response = await ProjectService.createProject(projectRequest);
    this.addProjects([response.data.entity]);
    if (leadAssignmentId) {
      // if there is a lead assignment id save a project link
      saveProjectLinks(Number(leadAssignmentId), [response.data.entity.id], ProjectProductTypes.Partition, false);
    }
    vxManager.projectsModule.setLeadAssignmentId(null); // clear the lead assignment id after saving the project link
    return response;
  }

  @action({ mode: 'mutate' })
  public async duplicateProject(operation: ProjectDuplicateOperation): Promise<void> {
    const { projectId, distributorId } = operation;
    const response = await ProjectService.duplicateProject(projectId, distributorId);
    await this.fetchProject(response.entity.id);
  }

  @action({ mode: 'mutate' })
  public async refreshProjectBom(projectId: string): Promise<void> {
    await ProjectService.refreshProjectBom(projectId);
  }

  @action({ mode: 'mutate' })
  public async deleteProject(projectId: string): Promise<void> {
    await ProjectService.deleteProject(projectId);
    this.removeProjectById(projectId);
    this.projectsNeedToLoad();
  }

  @action({ mode: 'mutate' })
  public async deleteProjects(projects: string[]): Promise<void> {
    await ProjectService.deleteProjects(projects);
    projects.forEach(proj => {
      this.removeProjectById(proj);
    });
    this.projectsNeedToLoad();
  }

  @action({ mode: 'mutate' })
  public async patchProject(operation: ProjectPatchOperation): Promise<void> {
    const { projectId, operations } = operation;
    const response = await ProjectService.patchProject(projectId, operations);
    this.updateProject(response);
  }

  @action({ mode: 'mutate' })
  public async patchProjectRoomSettings(operation: ProjectPatchOperation): Promise<void> {
    const { operations, projectId } = operation;
    await ProjectService.patchProjectRoomSettings(operations, projectId);
    await vxManager.roomsModule.fetchAllRoomsForProject(projectId);
  }

  @action({ mode: 'mutate' })
  public async patchProjectSettings(operation: ProjectPatchOperation): Promise<void> {
    const response = await ProjectSettingsService.patchProjectSettings(operation);
    this.setProjectSettings(response.data.entity);
  }

  @action({ mode: 'mutate' })
  public async patchProjectNotes(operation: ProjectPatchOperation): Promise<void> {
    const { projectId, operations } = operation;
    const response = await ProjectService.patchProject(projectId, operations);
    this.addProjects([response]);
  }

  @action({ mode: 'mutate' })
  public async patchProjectLevelToolbarOptions(operation: ProjectPatchOperation): Promise<void> {
    const { projectId, operations } = operation;

    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await ProjectService.patchProject(projectId, operations);
      if (this.getCurrentProject) {
        this.getCurrentProject.printedInstructions = response.printedInstructions;
        this.getCurrentProject.displayBowlBetweenBowls = response.displayBowlBetweenBowls;
        this.getCurrentProject.displayBowlWithinUnit = response.displayBowlWithinUnit;
        this.getCurrentProject.showBoltDimensions = response.showBoltDimensions;
      }
    });
  }

  @action({ mode: 'mutate' })
  public async patchProjectShippingAddress(request: ShippingAddressPayload): Promise<ShippingAddress> {
    const { projectId, shippingAddress } = request;
    const response = await ProjectService.setProjectShippingAddress(projectId, shippingAddress);
    this.setProjectShippingAddress(response);
    this.updateProjectLastUpdatedDate();

    await this.fetchProjectBom(this.currentProjectId);
    this.updateProject(await ProjectService.getProject(this.currentProjectId));
    return response;
  }

  @action({ mode: 'mutate' })
  public async deleteProjectShippingAddress(addressId: string): Promise<void> {
    if (!this.currentProjectId) {
      return;
    }
    await ProjectService.deleteAddress(this.getCurrentProject?.distributor.id ?? '', addressId);
    this.updateProject(await ProjectService.getProject(this.currentProjectId));
  }

  @action({ mode: 'mutate' })
  public async postRoomPartsEdit(request: RoomPartsEditRequest): Promise<RoomPartsResponse> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await RoomPartsService.postRoomPartsEdit(request);

      if (response.data.roomParts) {
        this.updateRoomBomParts({ roomId: request.roomId, roomParts: response.data.roomParts });
      } else {
        this.removeRoomBomParts({
          roomId: request.roomId,
          partId: request.partId,
        });
      }
      return response.data;
    });
  }
  @action({ mode: 'mutate' })
  public async postRoomSuggestedPartsEdit(request: RoomSuggestedPartsEditRequest): Promise<RoomPartsResponse> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await RoomPartsService.postRoomSuggestedPartsEdit(request);

      if (response.data.roomParts) {
        this.updateRoomBomParts({ roomId: request.roomId, roomParts: response.data.roomParts });
      }

      return response.data;
    });
  }

  @action({ mode: 'mutate' })
  public async deleteRoomPartsBomEdit(request: RoomPartsDeleteBomEditRequest): Promise<RoomPartsResponse> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await RoomPartsService.deleteRoomPartsBomEdit(request);
      this.removeRoomBomParts({
        roomId: request.roomId,
        partId: request.roomPartId,
      });
      return response.data;
    });
  }

  @action({ mode: 'mutate' })
  public async removeRoomCustomLengthPart(request: RoomCustomLengthPartRemovalRequest): Promise<RoomPartsResponse> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await RoomPartsService.removeCustomLengthPart(request);
      this.removeRoomCustomLengthBomParts({ roomId: request.roomId, partId: request.partId });
      return response.data;
    });
  }

  @action({ mode: 'mutate' })
  public async deleteRoomPartsEdit(request: RoomPartsDeleteRequest): Promise<boolean> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await RoomPartsService.deleteRoomPartsEdit(request.roomId);
      this.fetchRoomBom({ projectId: request.projectId, roomId: request.roomId });
      return response.status === 200;
    });
  }

  @action({ mode: 'mutate' })
  public async fetchProjectSpecialChargesHistory(projectId: string): Promise<void> {
    const specialCharges = await ProjectService.getProjectSpecialCharges(projectId);
    this.setSpecialChargesHistoryForProject({ projectId, specialCharges });
  }

  @action({ mode: 'mutate' })
  public async duplicateNoteToRooms(data: DuplicateNoteToRoomsRequest) {
    return await ProjectService.duplicateNoteToRooms(data);
  }

  @action({ mode: 'mutate' })
  public async changeProjectPartsFinish(data: ChangePartsFinish) {
    await ProjectService.changeProjectPartsFinish(data);
    await vxManager.projectPartsModule.fetchProjectParts(data.projectId);
    await this.fetchProjectBom(data.projectId);
    const response = await ProjectService.getProject(data.projectId);
    this.updateProject(response);
  }

  @action({ mode: 'mutate' })
  public async fetchProjectMarkup(projectId: string): Promise<void> {
    const markupResponse = await ProjectService.getProjectMarkup(projectId);
    this.setProjectMarkup(markupResponse);
  }

  @action({ mode: 'mutate' })
  public async saveProjectMarkup(request: SaveMarkupRequest): Promise<void> {
    const markupResponse = await ProjectService.saveProjectMarkup(request);
    this.setProjectMarkup(markupResponse);
  }

  @action({ mode: 'mutate' })
  public async resetProjectMarkup(projectId: string): Promise<void> {
    const markupResponse = await ProjectService.resetProjectMarkup(projectId);
    this.setProjectMarkup(markupResponse);
  }

  get getProjectStartDate() {
    return this.projectStartDate;
  }

  get getProjectEndDate() {
    return this.projectEndDate;
  }

  get getProjectMinPrice() {
    return this.projectMinPrice;
  }

  get getProjectMaxPrice() {
    return this.projectMaxPrice;
  }

  get allProjects() {
    return this.projects;
  }

  get getTotal() {
    return this.total;
  }

  get storeContainsAllProjects() {
    return this.total === this.projects.length;
  }

  get projectsLoaded() {
    return this.areProjectsLoaded;
  }

  get projectGetParams() {
    return this.projectParams;
  }

  get projectById() {
    return (id: string) => {
      return this.projects.find(project => project.id === id);
    };
  }

  get getCurrentProject(): Project | undefined {
    return this.projects.find(project => project.id === this.currentProjectId);
  }

  get getSpecialChargesHistoryByProjectId() {
    return (projectId: string): SpecialCharges[] | undefined => {
      return this.specialChargeHistoryByProject[projectId] || undefined;
    };
  }

  @mutation
  public setProjectGetParams(params: ProjectsGetParams) {
    this.projectParams = params;
  }

  @mutation public updateProjectLastUpdatedDate() {
    const current = this.projects.find(p => p.id === this.currentProjectId);
    if (!current) {
      return;
    }
    current.lastUpdatedDate = new Date().toISOString();
  }

  @mutation
  private updateProject(project: Project) {
    const current = this.projects.find(p => p.id === this.currentProjectId);
    if (!current) {
      ArrayHelper.addOrReplace(this.projects, [project]);
      return;
    }
    const updatedProject: Project = {
      ...project,
      projectCreator: current.projectCreator,
      roomParts: current.roomParts,
      roomBomParts: current.roomBomParts,
      unitList: current.unitList,
      partsTotal: current.partsTotal,
    };

    current.roomBomParts?.forEach(roomBomPart => {
      const room = vxManager.roomsModule.getRoomForProject(this.currentProjectId, roomBomPart.roomId);
      room && (room.bomIsMissingParts = roomBomPart.bomIsMissingParts);
    });

    ArrayHelper.addOrReplace(this.projects, [updatedProject]);
  }

  @mutation
  private setProjectMarkup(markupResponse: MarkupResponse) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      current.brandingOverride = markupResponse.brandingOverride;
      current.distributor.organizationBranding = markupResponse.organizationBranding;
      current.history = markupResponse.projectMarkupHistory;
    }
  }

  @mutation
  private addProjects(projects: Project[]) {
    ArrayHelper.addOrReplace(this.projects, projects);
  }

  @mutation
  private finishProjectLoading() {
    this.areProjectsLoaded = true;
  }

  @mutation
  private projectsNeedToLoad() {
    this.areProjectsLoaded = false;
  }

  @mutation
  private addProjectCreator(creator: User) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      current.projectCreator = creator;
    }
  }

  @mutation
  private setProjectSettings(settings: ProjectSettings) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      current.projectSettings = settings;
    }
  }

  @mutation
  private setProjectShippingAddress(address: ShippingAddress) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      current.shipTo = address;
    }
  }

  @mutation
  private setProjectRoomParts(roomParts: RoomParts[]) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      current.roomParts = roomParts;
    }
  }

  @mutation
  private setRoomBomParts(roomBomParts: RoomBomReply[]) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      current.roomBomParts = roomBomParts;
    }
  }

  @mutation
  private setRoomsTotals(roomBomParts: RoomBomReply[]) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      const currentRooms = vxManager.roomsModule.getRoomsForProject(current.id);
      currentRooms.forEach(room => {
        const currentRoomBomPart = roomBomParts.find(roomBomPart => roomBomPart.roomId === room.id);
        if (currentRoomBomPart) {
          room.netPrice = currentRoomBomPart.totalNetPrice;
        }
      });
    }
  }

  @mutation
  private setRoomBomPartsSingleRoom(roomBomParts: RoombBOMParts) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current?.roomBomParts) {
      const currentIndex = current.roomBomParts.findIndex(
        roomBomPart => roomBomPart.roomId === roomBomParts.entity.roomId
      );
      if (currentIndex !== -1) {
        current.roomBomParts[currentIndex] = roomBomParts.entity;
      } else {
        current.roomBomParts.push(roomBomParts.entity);
      }
    } else if (current) {
      current.roomBomParts = [roomBomParts.entity];
    }
    const room = vxManager.roomsModule.getRoomForProject(this.currentProjectId, roomBomParts.entity.roomId);
    if (room) {
      room.bomIsMissingParts = roomBomParts.entity.bomIsMissingParts;
    }
  }

  @mutation
  private setProjectUnits(units: Unit[]) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      current.unitList = units;
    }
  }

  @mutation
  private setPartsTotal(projectBom: ProjectRoomParts) {
    const current = this.projects.find(project => project.id === this.currentProjectId);
    if (current) {
      const { projectId, partsInBOMList, units, totalListPrice, roomsBomReply, ...partsTotal } = projectBom;
      current.partsTotal = partsTotal;
    }
  }

  @mutation
  private removeProjectById(projectId: string) {
    const index = this.projects.findIndex(proj => proj.id === projectId);
    this.projects.splice(index, 1);
  }

  @mutation
  private changeProjectStartDateFilter(startDate: string) {
    this.projectStartDate = startDate;
  }

  @mutation
  private changeProjectEndDateFilter(endDate: string) {
    this.projectEndDate = endDate;
  }

  @mutation
  private changeProjectMinPriceFilter(minPrice: number) {
    this.projectMinPrice = minPrice;
  }
  @mutation
  private changeProjectMaxPriceFilter(maxPrice: number) {
    this.projectMaxPrice = maxPrice;
  }

  @mutation
  private changeCurrentProject(projectId: string) {
    this.currentProjectId = projectId;
  }

  @mutation
  private changeTotal(newTotal: number) {
    this.total = newTotal;
  }

  @mutation private updateRoomBomParts(request: RoomBomPartsUpdate) {
    const project = this.projects.find(p => p.id === this.currentProjectId);
    if (!project) {
      return;
    }
    const roomBomParts = project.roomBomParts.find(r => r.roomId === request.roomId);
    if (roomBomParts) {
      roomBomParts.parts = request.roomParts;
    }
  }

  @mutation private removeRoomBomParts(request: RoomBomPartsDelete) {
    const project = this.projects.find(p => p.id === this.currentProjectId);
    if (!project || !project.roomBomParts) {
      return;
    }
    const roomBomParts = project.roomBomParts.find(r => r.roomId === request.roomId);
    if (roomBomParts) {
      const partIndex = roomBomParts.parts.findIndex(p => p.roomPart.id === request.partId);
      roomBomParts.parts.splice(partIndex, 1);
    }
  }

  @mutation private removeRoomCustomLengthBomParts(request: RoomBomPartsDelete) {
    const project = this.projects.find(p => p.id === this.currentProjectId);
    if (!project || !project.roomBomParts) {
      return;
    }

    const roomBomParts = project.roomBomParts.find(r => r.roomId === request.roomId);
    if (!roomBomParts) {
      return;
    }

    // Remove BOM edits
    roomBomParts.parts = roomBomParts.parts.filter(p => p.part.id !== request.partId || p.originalQuantity > 0);

    // Remove non BOM edits
    roomBomParts.parts = roomBomParts.parts.map(p =>
      p.part.id === request.partId && p.originalQuantity > 0 ? { ...p, quantity: 0 } : p
    );
  }

  @mutation private setSpecialChargesHistoryForProject(request: SpecialChargesHistoryUpdateRequest) {
    this.specialChargeHistoryByProject[request.projectId] = request.specialCharges;
  }

  @mutation private clearProjectsData() {
    this.projectStartDate = '';
    this.projectEndDate = '';
    this.projects = [];
    this.currentProjectId = '';
    this.areProjectsLoaded = false;
    this.total = 0;
  }

  get getLeadAssignmentId(): number | null {
    return this.leadAssignmentId;
  }

  @mutation
  setLeadAssignmentId(id: number | null) {
    this.leadAssignmentId = id;
  }
}

function handleGetProjectError(error: AxiosError) {
  if (error.response?.status === HttpStatusCode.NotFound) {
    redirectUserToHomePageWithNotification();
  }
  throw error;
}
