import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_maintenance = _resolveComponent("maintenance")!
  const _component_browserNotSupported = _resolveComponent("browserNotSupported")!
  const _component_navMenu = _resolveComponent("navMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_environmentFooter = _resolveComponent("environmentFooter")!
  const _component_cancelConfirmModal = _resolveComponent("cancelConfirmModal")!
  const _component_profilerModal = _resolveComponent("profilerModal")!
  const _component_commonErrorModal = _resolveComponent("commonErrorModal")!
  const _component_suggestedPartsModal = _resolveComponent("suggestedPartsModal")!
  const _component_listExportModal = _resolveComponent("listExportModal")!
  const _component_orderStatusLegendModal = _resolveComponent("orderStatusLegendModal")!
  const _component_leadsStatusLegendModal = _resolveComponent("leadsStatusLegendModal")!

  return (_ctx.isInMaintenanceMode)
    ? (_openBlock(), _createBlock(_component_maintenance, { key: 0 }))
    : (!_ctx.isBrowserSupported)
      ? (_openBlock(), _createBlock(_component_browserNotSupported, { key: 1 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.showMenu)
            ? (_openBlock(), _createBlock(_component_navMenu, _normalizeProps(_mergeProps({ key: 0 }, { showProfilerModal: _ctx.showProfilerModal })), null, 16))
            : _createCommentVNode("", true),
          _createElementVNode("main", null, [
            _createVNode(_component_router_view),
            _createVNode(_component_environmentFooter),
            _createVNode(_component_cancelConfirmModal),
            _createVNode(_component_profilerModal, { ref: "profilerModal" }, null, 512),
            _createVNode(_component_commonErrorModal),
            _createVNode(_component_suggestedPartsModal),
            _createVNode(_component_listExportModal),
            _createVNode(_component_orderStatusLegendModal),
            _createVNode(_component_leadsStatusLegendModal)
          ])
        ], 64))
}