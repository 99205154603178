import { defineComponent } from 'vue';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import { fetchLeadStatuses, LeadStatus, getPillTheme } from '@/endpoints/leadsService';
import EventBus from '@/eventBus';
import Events from '@/events';
import Pill from '@/shared/viewParts/pill/pill.vue';

export default defineComponent({
  name: 'leadsStatusLegendModal',
  components: {
    hadrianModal,
    Pill,
  },
  data() {
    return {
      leadStatuses: [] as LeadStatus[],
      reportStatuses: [] as LeadStatus[],
      modalRef: null as any,
    };
  },
  methods: {
    async loadLeadStatuses() {
      try {
        this.leadStatuses = await fetchLeadStatuses(0);
        this.reportStatuses = await fetchLeadStatuses(1);
      } catch (error) {
        alert('Error Displaying results');
      }
    },
    showLeadsStatusModal() {
      this.loadLeadStatuses();
      this.$nextTick(() => {
        if (this.modalRef) {
          this.modalRef.show();
        }
      });
    },
    cancel() {
      this.$emit('close');
    },
    getPillTheme(leadStatusId: number) {
      return getPillTheme(leadStatusId); // Assuming `getPillTheme` is a utility function imported from a service.
    },
  },
  mounted() {
    EventBus.$on(Events.showLeadsStatusModal, this.showLeadsStatusModal);
    this.modalRef = this.$refs.modalRef;
  },
  beforeUnmount() {
    EventBus.$off(Events.showLeadsStatusModal, this.showLeadsStatusModal);
  },
});
