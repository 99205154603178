import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-930ed2c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isClickable && _ctx.openInNewWindow)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "toUrl",
          class: _normalizeClass(["hadrian-router-link", _ctx.customClass]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openInNewWindowHandler && _ctx.openInNewWindowHandler(...args)), ["prevent"])),
          target: "_blank"
        }, _toDisplayString(_ctx.mainLabel), 3))
      : (_ctx.isClickable)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: _ctx.toUrl,
            class: _normalizeClass(["hadrian-router-link", _ctx.customClass]),
            disabled: _ctx.disabled || undefined
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.mainLabel), 1)
            ], undefined, true),
            _: 1
          }, 8, ["to", "class", "disabled"]))
        : (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "hadrian-router-link hadrian-router-link__inactive-label",
            disabled: _ctx.disabled || undefined
          }, _toDisplayString(_ctx.getAlternateLabel), 9, _hoisted_1))
  ]))
}