import { defineComponent } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';

export default defineComponent({
  name: 'hadrianRouterLink',
  props: {
    mainLabel: {
      type: String,
      required: true,
    },
    alternateLabel: {
      type: String,
    },
    url: {
      type: [String, Object as () => RouteLocationRaw],
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    openInNewWindow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    customClass: {
      type: String,
    },
  },
  computed: {
    isClickable(): boolean {
      return this.clickable && !!this.url;
    },
    toUrl(): string | RouteLocationRaw {
      return this.url || '';
    },
    getAlternateLabel(): string {
      return this.alternateLabel || this.mainLabel;
    },
  },
  methods: {
    handleClick(event: MouseEvent) {
      if (this.openInNewWindow) {
        event.preventDefault();
        this.openInNewWindowHandler(); // Open in a new tab
      }
    },
    openInNewWindowHandler() {
      const router = useRouter();
      const url = this.toUrl;

      // Open the URL in a new tab
      if (typeof url === 'string') {
        window.open(url, '_blank'); // Open in a new tab
      } else {
        const route = router.resolve(url);
        window.open(route.href, '_blank'); // Open in a new tab
      }
    },
  },
});
