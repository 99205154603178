const toggleMainMenu = 'toggleMainMenu';
const changedTab = 'changed::tab';
const projectDuplicateSearchTrigger = 'projectDuplicateSearchTrigger';
const editableFieldSaved = 'editableFieldSaved';
const createRoomLayoutChanged = 'createRoomLayoutChanged';
const createRoomUnitsChanged = 'createRoomUnitsChanged';
const checkboxChange = 'input';
const radioSelection = 'select';
const hardwarePackageChanged = 'hardwarePackageChanged';
const selectedFinishChanged = 'selectedFinishChanged';
const selectedPartitionChanged = 'selectedPartitionChanged';
const selectedMaterialChanged = 'selectedMaterialChanged';
const roomEdited = 'roomEdited';
const showDrawingToolbarModal = 'showDrawingToolbarModal';
const showAddressModal = 'showAddressModal';
const showNewQuotesModal = 'showNewQuotesModal';
const showNotesModal = 'showNotesModal';
const showLockerRoomNoteModal = 'showLockerRoomNoteModal';
const showLockerRoomAssistantModal = 'showLockerRoomAssistantModal';
const showSubmittalPackageModal = 'showSubmittalPackageModal';
const roomDesignerModified = 'roomDesignerModified';
const editRoomFinished = 'editRoomFinished';
const enableTooltip = 'bv::enable::tooltip';
const disableTooltip = 'bv::disable::tooltip';
const refresh = 'refresh';
const hoveredAnnotationChanged = 'hoveredAnnotationChanged';
const editedAnnotationChanged = 'editedAnnotationChanged';
const canvasNoteClicked = 'canvasNoteClicked';
const showRoomDesignerModal = 'showRoomDesignerModal';
const focusNewNote = 'focusNewNote';
const notesDetailMounted = 'notesDetailMounted';
const scrollToNote = 'scrollToNote';
const createRoomSelectUnitsNextDisabledChanged = 'createRoomSelectUnitsNextDisabledChanged';
const openProjectPartsModal = 'openProjectpartsModal';
const openAddPartsModal = 'openAddPartsModal';
const openResourceCenterModal = 'openResourceCenterModal';
const openLicenseAgreementModal = 'openLicenseAgreementModal';
const openDisableUserModal = 'openDisableUserModal';
const getRoomBom = 'getRoomBom';
const getRoomBomDone = 'getRoomBomDone';
const quoteableOnPrevious = 'quoteable-on-previous';
const updateOrders = 'updateOrders';
const updateOrdersParts = 'updateOrdersParts';
const showRoomPackageModal = 'showRoomPackageModal';
const formatMeasureInput = 'formatMeasureInput';
const toggleOnNoteDuplicationModel = 'toggleOnNoteDuplicationModel';
const forceResetToDefault = 'forceResetToDefault';
const hideContextualMenu = 'hideContextualMenu';
const selectedWashroomAccessoriesTypeChanged = 'selectedWashroomAccessoriesTypeChanged';
const selectedDryerModelChanged = 'selectedDryerModelChanged';
const toggleCardModal = 'toggleCardModal';
const toggleCardPaintModal = 'toggleCardPaintModal';
const toggleDateModal = 'toggleDateModal';
const onDeleteFilter = 'onDeleteFilter';
const updateAffValue = 'updateAffValue';
const showListExportModal = 'showListExportModal';
const showOrderStatusModal = 'showOrderStatusModal';
const showLeadsStatusModal = 'showLeadsStatusModal';

const Events = {
  toggleMainMenu,
  projectDuplicateSearchTrigger,
  changedTab,
  editableFieldSaved,
  createRoomLayoutChanged,
  createRoomUnitsChanged,
  checkboxChange,
  radioSelection,
  hardwarePackageChanged,
  roomEdited,
  selectedFinishChanged,
  selectedPartitionChanged,
  selectedMaterialChanged,
  selectedDryerModelChanged,
  selectedWashroomAccessoriesTypeChanged,
  showDrawingToolbarModal,
  showAddressModal,
  showNewQuotesModal,
  showNotesModal,
  showLockerRoomNoteModal,
  showLockerRoomAssistantModal,
  showSubmittalPackageModal,
  showRoomPackageModal,
  roomDesignerModified,
  editRoomFinished,
  enableTooltip,
  disableTooltip,
  refresh,
  hoveredAnnotationChanged,
  editedAnnotationChanged,
  canvasNoteClicked,
  showRoomDesignerModal,
  focusNewNote,
  notesDetailMounted,
  scrollToNote,
  getRoomBom,
  getRoomBomDone,
  createRoomSelectUnitsNextDisabledChanged,
  openProjectPartsModal,
  openAddPartsModal,
  openResourceCenterModal,
  openLicenseAgreementModal,
  openDisableUserModal,
  quoteableOnPrevious,
  updateOrders,
  updateOrdersParts,
  formatMeasureInput,
  toggleOnNoteDuplicationModel,
  forceResetToDefault,
  hideContextualMenu,
  toggleCardModal,
  toggleCardPaintModal,
  toggleDateModal,
  onDeleteFilter,
  updateAffValue,
  showListExportModal,
  showOrderStatusModal,
  showLeadsStatusModal,
};

export default Events;
