import HttpService from './HttpService';
import { PillThemes } from '@/shared/viewParts/pill/pill';
import { IconColors } from '@/shared/viewParts/filteredSelector/filteredSelector';
import Organization from '@/models/permission/organization';
import LeadAssignment from '@/models/leads/LeadAssignment';
import LeadDetails from '@/models/leads/Lead';
import Lead from '@/models/leads/Lead';
import LeadAssignmentAnswer from '@/models/leads/LeadAssignmentAnswer';
import LeadQuestionAnswers from '@/models/leads/LeadQuestionAnswers';

const DEFAULT_LEADS_SORTBY = 'CreationDate';
const DEFAULT_SORT = 'DESC';
const DEFAULT_ASSIGNMENTS_SORTBY = 'DateAssigned';
const DEFAULT_PROJECTS_SORTBY = 'LastUpdated';

const BASE_URL = `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_API_PREFIX}Leads`;
const ASSIGNMENTS_URL = `${BASE_URL}/assignments`;
const PROJECTS_URL = `${BASE_URL}/projects`;

interface ILeadOrganizationRequest {
  OrganizationsList: string[];
  leadId: number;
}

export class LeadStatus {
  leadStatusId: number;
  label: string;
  description: string;
  statusType: number;
}
export enum LeadStatuses {
  Completed = 8,
  Closed = 9,
  Archived = 10,
}
export enum LeadAssignmentStatus {
  New = 1,
  Assigned = 2,
  EndingSoon = 3,
  Ended = 4,
  Awaiting = 5,
  BidSend = 6,
  IntendToBid = 7,
  NoBid = 8,
  BidWon = 9,
  BidLoss = 10,
}

export interface LinkedProject {
  leadProjectLinkId: number;
  leadAssignmentId: number;
  projectId: number;
  projectNumber: string;
  projectName: string;
  dateLinked: string;
  linkedByUserId: number;
  productType: number;
}
export const LeadAssignmentStatusLabels: { [key in LeadAssignmentStatus]: string } = {
  [LeadAssignmentStatus.New]: 'NEW',
  [LeadAssignmentStatus.Assigned]: 'ASSIGNED',
  [LeadAssignmentStatus.EndingSoon]: 'ENDING SOON',
  [LeadAssignmentStatus.Ended]: 'ENDED',
  [LeadAssignmentStatus.Awaiting]: 'AWAITING',
  [LeadAssignmentStatus.BidSend]: 'BID SEND',
  [LeadAssignmentStatus.IntendToBid]: 'INTEND TO BID',
  [LeadAssignmentStatus.NoBid]: 'NO BID',
  [LeadAssignmentStatus.BidWon]: 'BID WON',
  [LeadAssignmentStatus.BidLoss]: 'BID LOSS',
};

export enum BidType {
  AWAITING = 0,
  Blue = 'blue',
  Pink = 'pink',
}

export class LeadsPageRequestParams {
  page?: number;
  PerPage?: number;
  SortBy?: string = DEFAULT_LEADS_SORTBY;
  Sort?: string = DEFAULT_SORT;
  Search?: string;
  ProductType?: string;
  SalesArea?: string;
  LeadStatusId?: number;
  HasAssignments?: boolean;
  DateFilterField?: string;
  DateFilterFrom?: string;
  DateFilterTo?: string;
}
export class AssignmentsPageRequestParams {
  page?: number;
  PerPage?: number;
  SortBy?: string = DEFAULT_ASSIGNMENTS_SORTBY;
  Sort?: string = DEFAULT_SORT;
  Search?: string;
  ProductType?: string;
  LeadStatusId?: number;
  LeadAssignmentStatusId?: number;
  DateFilterField?: string;
  DateFilterFrom?: string;
  DateFilterTo?: string;
}

export class ProjectsToLinkPageRequestParams {
  leadAssignmentId: number;
  page?: number;
  PerPage?: number;
  SortBy?: string = DEFAULT_PROJECTS_SORTBY;
  Sort?: string = DEFAULT_SORT;
  Search?: string;
  productType?: number;
}

export interface PaginatedLeadsResponse {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  next_page_url: string | null;
  prev_page_url: string | null;
  entities: Lead[];
}

export interface PaginatedAssignmentResponse {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  next_page_url: string | null;
  prev_page_url: string | null;
  entities: LeadAssignment[];
}

export interface PaginatedProjectsToLinkResponse {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  next_page_url: string | null;
  prev_page_url: string | null;
  entities: ProjectToLinkEntity[];
}

export interface ProjectToLinkEntity {
  projectId: string;
  productType: number;
  name: string;
  lastUpdatedDate: string;
  projectNumber: string;
  cachedNetPrice: number;
  jdeCustomerId: string;
  organizationName: string;
  dateLinked: string;
  linkedByUserId: string;
  leadAssignmentId: number;
  leadProjectLinkId: number;
}

export interface SendDateResponnse {
  newDate: Date;
}

export async function fetchLeads(params: LeadsPageRequestParams): Promise<PaginatedLeadsResponse> {
  try {
    const formatDate = (date: string | undefined): string | undefined => {
      return date ? new Date(date).toISOString().split('T')[0] : undefined;
    };
    const response = await HttpService.get<PaginatedLeadsResponse>(BASE_URL, {
      params: {
        page: params.page,
        perPage: params.PerPage,
        sortBy: params.SortBy,
        sortDirection: params.Sort,
        searchText: params.Search,
        productType: params.ProductType,
        salesArea: params.SalesArea,
        leadStatusId: params.LeadStatusId,
        hasAssignments: params.HasAssignments,
        dateFilterField: params.DateFilterField,
        dateFilterFrom: formatDate(params.DateFilterFrom),
        dateFilterTo: formatDate(params.DateFilterTo),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch leads');
  }
}
export async function fetchAssignmentsPage(params: AssignmentsPageRequestParams): Promise<PaginatedAssignmentResponse> {
  try {
    const formatDate = (date: string | undefined): string | undefined => {
      return date ? new Date(date).toISOString().split('T')[0] : undefined;
    };
    const response = await HttpService.get<PaginatedAssignmentResponse>(ASSIGNMENTS_URL, {
      params: {
        page: params.page,
        perPage: params.PerPage,
        sortBy: params.SortBy,
        sortDirection: params.Sort,
        searchText: params.Search,
        productType: params.ProductType,
        leadStatusId: params.LeadStatusId,
        leadAssignmentStatusId: params.LeadAssignmentStatusId,
        dateFilterField: params.DateFilterField,
        dateFilterFrom: formatDate(params.DateFilterFrom),
        dateFilterTo: formatDate(params.DateFilterTo),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch leads');
  }
}

export async function fetchProjectsToLink(
  params: ProjectsToLinkPageRequestParams
): Promise<PaginatedProjectsToLinkResponse> {
  try {
    //console.log('fetchProjectsToLink - Request Params:', params);

    const response = await HttpService.get<PaginatedProjectsToLinkResponse>(PROJECTS_URL, {
      params: {
        leadAssignmentId: params.leadAssignmentId,
        page: params.page,
        perPage: params.PerPage,
        sortBy: params.SortBy,
        sortDirection: params.Sort,
        searchText: params.Search,
        productType: params.productType,
      },
    });

    //    console.log('fetchProjectsToLink - Response:', response.data);
    return response.data;
  } catch (error) {
    //  console.error('fetchProjectsToLink - Error:', error);
    throw new Error('Failed to fetch projects');
  }
}

export async function fetchSalesAreas(): Promise<string[]> {
  try {
    const response = await HttpService.get<string[]>(`${BASE_URL}/salesAreas`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch sales areas');
  }
}
export async function fetchLeadAssignments(leadId: number): Promise<LeadAssignment[]> {
  try {
    const response = await HttpService.get<LeadAssignment[]>(`${BASE_URL}/${leadId}/assignments`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch lead assignments');
  }
}
export async function fetchValidAnswers(): Promise<LeadQuestionAnswers> {
  try {
    const response = await HttpService.get<LeadQuestionAnswers>(`${BASE_URL}/assignments/answers`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch valid answers');
  }
}
export async function fetchLeadAssignmentAnswers(leadAssignmentId: number): Promise<LeadAssignmentAnswer> {
  try {
    const response = await HttpService.get<LeadAssignmentAnswer>(`${BASE_URL}/assignments/answers/${leadAssignmentId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch lead assignments');
  }
}
export async function fetchAssignmentDetails(leadAssignmentId: number): Promise<LeadAssignment> {
  try {
    const response = await HttpService.get<LeadAssignment>(`${BASE_URL}/assignments/${leadAssignmentId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch lead assignments');
  }
}
export async function patchAssignmentNotes(leadAssignmentId: number, notes: string): Promise<void> {
  try {
    const requestPayload = {
      Notes: notes,
    };
    await HttpService.patch(`${BASE_URL}/assignments/${leadAssignmentId}/notes`, requestPayload);
  } catch (error) {
    throw new Error('Failed to patch assignment notes');
  }
}
export async function deleteLeadAssignment(leadAssignmentId: number): Promise<LeadAssignment[]> {
  try {
    const response = await HttpService.delete(`${BASE_URL}/assignments/${leadAssignmentId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete lead assignment: ' + (error as Error).message);
  }
}
export async function deleteProjectLink(leadProjectLinkId: number): Promise<void> {
  try {
    await HttpService.delete(`${BASE_URL}/assignments/projects/${leadProjectLinkId}`);
  } catch (error) {
    throw new Error('Failed to delete project link: ' + (error as Error).message);
  }
}

export async function fetchLeadDetails(leadId: number): Promise<Lead> {
  try {
    const response = await HttpService.get<Lead>(`${BASE_URL}/${leadId}`);
    //console.log(response.data);
    return response.data; // No need for instantiation
  } catch (error) {
    throw new Error('Failed to fetch lead assignments');
  }
}

export async function PatchLeadCompletionDate(leadId: number, newDate: Date): Promise<boolean> {
  try {
    // Convert Date to ISO 8601 string format
    const formattedDate = newDate.toISOString();

    // Create the JSON object that matches the CompleteDateUpdate class structure
    const requestPayload = {
      CompletionDate: formattedDate,
    };
    // Send the request with the payload
    const response = await HttpService.patch(`${BASE_URL}/${leadId}/completiondate`, requestPayload);
    // Return the response data as a boolean
    return response.data as boolean;
  } catch (error) {
    throw new Error('Failed to patch lead bid date');
  }
}

export async function PatchLeadBidDate(leadId: number, newDate: Date): Promise<boolean> {
  try {
    // Convert Date to ISO 8601 string format
    const formattedDate = newDate.toISOString();

    // Create the JSON object that matches the CompleteDateUpdate class structure
    const requestPayload = {
      BidDate: formattedDate,
    };
    //debugger;
    // Send the request with the payload
    const response = await HttpService.patch(`${BASE_URL}/${leadId}/biddate`, requestPayload);
    // Return the response data as a boolean
    return response.data as boolean;
  } catch (error) {
    throw new Error('Failed to patch lead bid date');
  }
}

export async function AssignLeadOrganizations(leadId: number, orgs: LeadAssignment[]): Promise<LeadAssignment[]> {
  try {
    const organizationIds: string[] = orgs.map(org => org.organizationId); // Collect organization IDs
    const requestPayload = {
      assignments: organizationIds,
    };
    const response = await HttpService.post(`${BASE_URL}/${leadId}/assignments`, organizationIds);
    // Return the response data as a boolean
    return response.data;
  } catch (error) {
    throw new Error('Failed to assign lead organizations');
  }
}
export async function SaveLeadAssignmentAnswers(
  leadAssignmentId: number,
  leadAnswers: LeadAssignmentAnswer
): Promise<boolean> {
  try {
    const response = await HttpService.post(`${BASE_URL}/${leadAssignmentId}/assignments/answers`, leadAnswers);
    return response.data; // Return the response data directly if it's a boolean
  } catch (error) {
    throw new Error('Failed to save answers.');
  }
}

export function getPillTheme(leadStatusId: number): PillThemes {
  const leadStatusPills = [
    PillThemes.OpaqueBlue,
    PillThemes.OpaqueGreen,
    PillThemes.OpaqueOrange,
    PillThemes.OpaqueGrey,
    PillThemes.PastelWhite,
    PillThemes.PastelBlue,
    PillThemes.PastelYellow,
    PillThemes.PastelPurple,
    PillThemes.PastelGreen,
    PillThemes.PastelRed,
  ];
  // this assumes the leadStatusId coresponds to the order of array above.
  return leadStatusId >= 1 && leadStatusId <= leadStatusPills.length
    ? leadStatusPills[leadStatusId - 1]
    : PillThemes.Default;
}
export function getPillThemeIconColor(leadStatusId: number): IconColors {
  const leadStatusPills = [
    IconColors.OpaqueBlue,
    IconColors.OpaqueGreen,
    IconColors.OpaqueOrange,
    IconColors.OpaqueGrey,
    IconColors.PastelWhite,
    IconColors.PastelBlue,
    IconColors.PastelYellow,
    IconColors.PastelPurple,
    IconColors.PastelGreen,
    IconColors.PastelRed,
  ];
  // this assumes the leadStatusId coresponds to the order of array above.
  return leadStatusId >= 1 && leadStatusId <= leadStatusPills.length
    ? leadStatusPills[leadStatusId - 1]
    : IconColors.Blue;
}
export function getAssignedDetailsVisibility(leadStatusId: number): boolean {
  return Object.values(LeadStatuses).includes(leadStatusId);
}

export async function fetchLeadStatuses(statusType: number): Promise<LeadStatus[]> {
  try {
    const response = await HttpService.get<LeadStatus[]>(`${BASE_URL}/leadStatuses`, { params: { statusType } });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch lead statuses');
  }
}

export async function saveProjectLinks(
  leadAssignmentId: number,
  projectIds: string[],
  productType: number, // Assuming projectIds are GUIDs represented as strings
  deleteProjectLinks = true // Default value for deleteProjectLinks
): Promise<void> {
  try {
    const response = await HttpService.post(`${ASSIGNMENTS_URL}/${leadAssignmentId}/projectlinks`, projectIds, {
      params: { deleteProjectLinks, productType }, // Pass deleteProjectLinks as a query parameter
    });

    // No need to return anything if the response is void
  } catch (error) {
    throw new Error('Failed to save project links: ' + (error as Error).message);
  }
}

export async function fetchLinkedProjects(leadAssignmentId: number): Promise<LinkedProject[]> {
  try {
    const response = await HttpService.get<LinkedProject[]>(`${ASSIGNMENTS_URL}/${leadAssignmentId}/projects`);
    return response.data; // Return the list of linked projects
  } catch (error) {
    throw new Error('Failed to fetch linked projects: ' + (error as Error).message);
  }
}
