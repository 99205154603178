import EnumHelper from '@/helpers/enumHelper';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import { vxManager } from '../store';

const IS_NAV_MENU_OPEN_KEY = 'hadrian-isnavmenuopen';
const IS_COUNTER_SALES_ON_KEY = 'hadrian-iscountersaleson';

export enum BackendEnvironment {
  Qa = 'qa',
  Dev = 'dev',
  Staging = 'staging',
  Uat = 'uat',
  Production = 'production',
  Localhost = 'localhost',
}

@Module()
export class AppStateModule extends VuexModule {
  private _frontendVersion = '';
  private _updateModalIsOpen = false;
  private isHydrated = false;
  private loadingRequests = 0;
  private isCounterSalesOn = false;
  private serverErrorAlreadyShown = false;
  private isProjectListExpanded = false;
  private isOrderListExpanded = false;
  private isLeadListExpanded = false;

  @action({ mode: 'mutate' })
  public async changeHydrateState(value: boolean): Promise<void> {
    this.setIsHydrated(value);
    if (!value) {
      this._resetLoading();
    }
  }

  @action({ mode: 'mutate' })
  public async clearState(): Promise<void> {
    this.clearAppStateData();
  }

  @action({ mode: 'mutate' })
  public async fetchState(): Promise<void> {
    this.setIsCounterSalesOn(localStorage.getItem(IS_COUNTER_SALES_ON_KEY) === 'true');
  }

  @action({ mode: 'mutate' })
  public async setIsNavMenuOpen(value: boolean): Promise<void> {
    localStorage.setItem(IS_NAV_MENU_OPEN_KEY, String(value));
  }

  @action({ mode: 'mutate' })
  public async setLoading(isLoading: boolean): Promise<void> {
    this.handleLoadingRequest(isLoading ? 1 : -1);
  }

  @action({ mode: 'mutate' })
  public async toggleIsCounterSalesOn(): Promise<void> {
    this.setIsCounterSalesOn(!this.isCounterSalesOn);
  }

  @action({ mode: 'mutate' })
  public setServerErrorAlreadyShown(value: boolean): void {
    this._setServerErrorAlreadyShown(value);
  }

  @action({ mode: 'mutate' })
  public updateFrontendVersion(value: string): void {
    this._setFrontendVersion(value);
  }

  @action({ mode: 'mutate' })
  public setUpdateModalIsOpen(value: boolean): void {
    this._setUpdateModalIsOpen(value);
  }

  @action({ mode: 'mutate' })
  public resetLoading() {
    this._resetLoading();
  }

  public get getIsProjectListExpanded(): boolean {
    return this.isProjectListExpanded;
  }

  public get getIsOrderListExpanded(): boolean {
    return this.isOrderListExpanded;
  }
  public get getIsLeadListExpanded(): boolean {
    return this.getIsLeadListExpanded;
  }

  public get getIsHydrated() {
    return this.isHydrated;
  }

  public get isNavMenuOpen(): boolean {
    const item = localStorage.getItem(IS_NAV_MENU_OPEN_KEY);

    if (!item) {
      localStorage.setItem(IS_NAV_MENU_OPEN_KEY, 'true');
      return true;
    }

    return item === 'true';
  }

  public get getUIVersion() {
    return process.env.VUE_APP_VERSION;
  }

  public get getServerUrl(): string {
    return process.env.VUE_APP_SERVER_URL;
  }

  public get loading() {
    return this.loadingRequests !== 0;
  }

  public get getIsCounterSalesOn(): boolean {
    return this.isCounterSalesOn;
  }

  public get getServerErrorAlreadyShown(): boolean {
    return this.serverErrorAlreadyShown;
  }

  public get frontendVersion(): string {
    return this._frontendVersion;
  }

  public get backendEnvironment(): BackendEnvironment {
    if (/api\./.test(this.getServerUrl)) {
      return BackendEnvironment.Production;
    }

    const env = /api-([a-z]+)/.exec(this.getServerUrl);
    if (env && env.length === 2) {
      return (
        (EnumHelper.getStringValues(BackendEnvironment).find(value => value === env[1]) as BackendEnvironment) ??
        BackendEnvironment.Localhost
      );
    }

    return BackendEnvironment.Localhost;
  }

  public get showEnvironmentFooter(): boolean {
    return this.backendEnvironment !== BackendEnvironment.Production;
  }

  public get updateModalIsOpen(): boolean {
    return this._updateModalIsOpen;
  }

  @mutation public setIsProjectListExpanded(value: boolean) {
    this.isProjectListExpanded = value;
  }

  @mutation public setIsOrderListExpanded(value: boolean) {
    this.isOrderListExpanded = value;
  }

  @mutation public setIsLeadListExpanded(value: boolean) {
    this.isLeadListExpanded = value;
  }

  @mutation private setIsHydrated(value: boolean) {
    this.isHydrated = value;
  }

  @mutation private setIsCounterSalesOn(value: boolean) {
    localStorage.setItem(IS_COUNTER_SALES_ON_KEY, String(value));
    this.isCounterSalesOn = value;
  }

  @mutation private handleLoadingRequest(value: number) {
    this.loadingRequests += value;
    if (this.loadingRequests < 0) {
      this.loadingRequests = 0;
      throw new Error('Loading has been dismissed more often than it was triggered. Fix this!');
    }
  }

  @mutation private _setServerErrorAlreadyShown(value: boolean) {
    this.serverErrorAlreadyShown = value;
  }

  @mutation private _setFrontendVersion(value: string) {
    this._frontendVersion = value;
  }

  @mutation private _setUpdateModalIsOpen(value: boolean) {
    this._updateModalIsOpen = value;
  }

  @mutation private _resetLoading() {
    this.loadingRequests = 0;
    vxManager.roomDesignerModule.resetRoomLoading();
  }

  @mutation
  private clearAppStateData() {
    this.isHydrated = false;
    this._updateModalIsOpen = false;
    this.loadingRequests = 0;
    this.isCounterSalesOn = false;
    this.serverErrorAlreadyShown = false;
    vxManager.roomDesignerModule.resetRoomLoading();
  }
}
