import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "h-table" }
const _hoisted_2 = { class: "h-table__data" }
const _hoisted_3 = { class: "h-table__header" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "h-table__header-cell"
}
const _hoisted_6 = {
  key: 1,
  class: "h-table__header-cell"
}
const _hoisted_7 = {
  key: 0,
  class: "h-table__expand-button-wrapper"
}
const _hoisted_8 = { class: "h-table__body" }
const _hoisted_9 = {
  key: 0,
  class: "h-table__body-row h-table__body-row--empty"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "h-table__body-cell h-table__body-cell--actions"
}
const _hoisted_12 = {
  key: 1,
  class: "h-table__body-cell"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 0,
  class: "h-table__body-cell h-table__body-cell--footer"
}
const _hoisted_15 = {
  key: 1,
  class: "h-table__body-cell h-table__body-cell--footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconChevron = _resolveComponent("iconChevron")!
  const _component_hadrianTooltip = _resolveComponent("hadrianTooltip")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", {
          class: "h-table__header-row",
          style: _normalizeStyle(_ctx.rowStyle)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createElementBlock("th", {
              key: `thead-${column.key}`,
              class: "h-table__header-cell"
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getHeaderCellContainerElement(column)), {
                type: !!_ctx.rows.length && column.sortable ? 'button' : null,
                onClick: ($event: any) => (_ctx.onHeaderCellClick(column)),
                class: _normalizeClass(["h-table__header-cell-container", { [column.class]: true, [column.headerClass]: true }]),
                style: _normalizeStyle({ 'justify-content': _ctx.getColumnAlignment(column) })
              }, {
                default: _withCtx(() => [
                  (!!_ctx.rows.length && column.sortable)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: _ctx.getSortIndicator(column)
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, [
                    _renderSlot(_ctx.$slots, `column-label-${column.key}`, { column: column }, () => [
                      _renderSlot(_ctx.$slots, "column-label", { column: column }, () => [
                        _createTextVNode(_toDisplayString(column.key), 1)
                      ])
                    ])
                  ])
                ], undefined, true),
                _: 2
              }, 1032, ["type", "onClick", "class", "style"]))
            ]))
          }), 128)),
          (_ctx.actionable)
            ? (_openBlock(), _createElementBlock("th", _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.expandable)
            ? (_openBlock(), _createElementBlock("th", _hoisted_6, [
                (_ctx.showHeaderExpand)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_hadrianTooltip, {
                        tooltipText: _ctx.expandAllButtonLabel,
                        anchor: "center"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", {
                            type: "button",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onToggleExpandAll && _ctx.onToggleExpandAll(...args))),
                            class: _normalizeClass(["h-table__expand-button h-table__expand-button--header", { 'h-table__expand-button--open': _ctx.isAllExpanded }])
                          }, [
                            _createVNode(_component_iconChevron)
                          ], 2)
                        ], undefined, true),
                        _: 1
                      }, 8, ["tooltipText"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 4)
      ]),
      _createElementVNode("tbody", _hoisted_8, [
        (!_ctx.rows.length && !!_ctx.emptyText)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
              _createElementVNode("td", null, _toDisplayString(_ctx.emptyText), 1)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: _ctx.getRowUniqueId(row, index),
            class: _normalizeClass(["h-table__body-row", [
            _ctx.rowTheme(row),
            _ctx.rowClass(row),
            {
              selected: _ctx.isRowSelected(row),
              selectable: _ctx.selectable,
            },
          ]]),
            style: _normalizeStyle(_ctx.rowStyle),
            onClick: ($event: any) => (_ctx.selectable ? _ctx.toggleRowSelection(row) : null)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("td", {
                key: `tbody-${column.key}`,
                class: _normalizeClass(["h-table__body-cell", column.class]),
                style: _normalizeStyle({ 'justify-content': _ctx.getColumnAlignment(column) })
              }, [
                _createElementVNode("div", null, [
                  _renderSlot(_ctx.$slots, `column-value-${column.key}`, { row: row }, () => [
                    _renderSlot(_ctx.$slots, "column-value", {
                      row: row,
                      column: column
                    }, () => [
                      _createTextVNode(_toDisplayString(row[column.key]), 1)
                    ])
                  ])
                ])
              ], 6))
            }), 128)),
            (_ctx.actionable)
              ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                  _renderSlot(_ctx.$slots, `row-actions-${row[_ctx.keyAttribute]}`, { row: row }, () => [
                    _renderSlot(_ctx.$slots, "row-actions", { row: row })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandable)
              ? (_openBlock(), _createElementBlock("td", _hoisted_12, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: ($event: any) => (_ctx.onToggleExpand(row, index)),
                    class: _normalizeClass(["h-table__expand-button", { 'h-table__expand-button--open': !!_ctx.expansion[_ctx.getRowUniqueId(row, index)] }])
                  }, [
                    _createVNode(_component_iconChevron)
                  ], 10, _hoisted_13)
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandable)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 2,
                  class: _normalizeClass(["h-table__row-expansion", { 'h-table__row-expansion--open': !!_ctx.expansion[_ctx.getRowUniqueId(row, index)] }])
                }, [
                  _renderSlot(_ctx.$slots, `row-expansion-${row[_ctx.keyAttribute]}`, { row: row }, () => [
                    _renderSlot(_ctx.$slots, "row-expansion", { row: row })
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ], 14, _hoisted_10))
        }), 128)),
        (_ctx.hasFooter)
          ? (_openBlock(), _createElementBlock("tr", {
              key: 1,
              class: "h-table__body-row h-table__body-row--footer",
              style: _normalizeStyle(_ctx.rowStyle)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: `tfooter-${column.key}`,
                  class: "h-table__body-cell h-table__body-cell--footer",
                  style: _normalizeStyle({ 'justify-content': _ctx.getColumnAlignment(column) })
                }, [
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, `column-footer-${column.key}`, {}, () => [
                      _renderSlot(_ctx.$slots, "column-footer", { column: column })
                    ])
                  ])
                ], 4))
              }), 128)),
              (_ctx.actionable)
                ? (_openBlock(), _createElementBlock("td", _hoisted_14))
                : _createCommentVNode("", true),
              (_ctx.expandable)
                ? (_openBlock(), _createElementBlock("td", _hoisted_15))
                : _createCommentVNode("", true)
            ], 4))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}